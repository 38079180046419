import { AxiosRequestConfig } from "axios";
import { TEndpoint } from "#types/api.core";
import Api from "@core/api.core";
import getConfig from "next/config";
import { mergeRight } from "ramda";
import { TPublicVacancyAlert, TVacancyAlert } from "#typesmimir";
import { getToken } from "@utilsauth";

const { jobsApiHost } = getConfig().publicRuntimeConfig;

export class MimirRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    mimir: {
      alert: "/v1/mimir-alerts/alert",
      alertPublic: "/v1/mimir-alerts/alert-public"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: jobsApiHost }, baseOptions));
  }

  get getHeadersAuth() {
    return {
      Authorization: `Bearer ${getToken()}`
    };
  }

  async createAlert(params: TVacancyAlert) {
    return await this.post(this.endpoints.mimir.alert, params, { headers: this.getHeadersAuth }).then(
      (response) => response.data
    );
  }

  async createPublicAlert(params: TPublicVacancyAlert) {
    return await this.post(this.endpoints.mimir.alertPublic, params, { headers: this.getHeadersAuth }).then(
      (response) => response.data
    );
  }
}

export default new MimirRepository();
