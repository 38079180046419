import { isServerSide } from "@constants/environment.constant";
import { serverSideDataLayer } from "@utils/googleTagManager";
import { TProductClick } from "#types/tagManager";
import { IDataLayer } from "@interfaces/tagManager.interface";

export class TagManagerRepository {
  constructor(protected dataLayer: IDataLayer) {}

  productClick(product: TProductClick): boolean {
    return this.dataLayer.push({
      ecommerce: { click: { actionField: { list: "search" }, products: [product] } },
      event: "productClick"
    });
  }

  productImpression(productList: TProductClick[]): boolean {
    return this.dataLayer.push({
      ecommerce: { impressions: [...productList] },
      event: "productImpression"
    });
  }

  productDetail(product: TProductClick): boolean {
    return this.dataLayer.push({
      ecommerce: { click: { actionField: { list: product.brand }, products: [product] } },
      event: "productDetail"
    });
  }

  viewItemList(viewItemListJson: any): boolean {
    return this.dataLayer.push({ ...viewItemListJson });
  }

  addToCartV4(viewItemListJson: any): boolean {
    return this.dataLayer.push({ ...viewItemListJson });
  }

  purchaseV4(viewItemListJson: any): boolean {
    return this.dataLayer.push({ ...viewItemListJson });
  }

  createAccountCTA(): boolean {
    return this.dataLayer.push({ event: "Creacion de cuenta CTA" });
  }

  authentication(userId: number): boolean {
    return this.dataLayer.push({
      event: "authentication",
      userId: userId,
      userType: "simple"
    });
  }
}

export default new TagManagerRepository(isServerSide ? serverSideDataLayer : window.dataLayer);
