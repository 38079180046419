import getConfig from "next/config";

export const isClientSide: boolean = typeof window !== "undefined";
export const isServerSide: boolean = typeof window === "undefined";
export const fullBasePath: string = getConfig().publicRuntimeConfig.fullBasePath;
export const mainBasePath: string = getConfig().publicRuntimeConfig.mainBasePath;
export const countryCode: string = mainBasePath.replace("/", "");
export const loggedUserHost: string = getConfig().publicRuntimeConfig.loggedUserHost;
export const signUpUserHost: string = getConfig().publicRuntimeConfig.signUpUserHost || loggedUserHost;
export const language: string = getConfig().publicRuntimeConfig.mainLang;
export const authEmitterUrl: string = getConfig().publicRuntimeConfig.authEmitter;
export const registryHost: string = getConfig().publicRuntimeConfig.registryHost || "http://localhost:8081";
export const oauthFrontHost: string = getConfig().publicRuntimeConfig.oauthFrontHost || "http://localhost:8080";
export const jobsHostBase: string = getConfig().publicRuntimeConfig.jobsHost || "http://localhost:8060";
export const HomeHostBase: string = getConfig().publicRuntimeConfig.urlHomeNext || "http://localhost:8061";
export const UserBackToken: string = getConfig().publicRuntimeConfig.publicUserBackToken;
export const LegacyProfile: string = getConfig().publicRuntimeConfig.legacyProfile || "http://localhost:8082";
export const ClientOauthID: string = getConfig().publicRuntimeConfig.clientOauthId || "";
export const ProfileRedirectUri: string = getConfig().publicRuntimeConfig.profileRedirectUri || "";
export const WidgetHost = {
  spe: (() => {
    const baseHost = getConfig().publicRuntimeConfig.widgetHost;
    return baseHost ? `${baseHost}/spe.widget.js` : "http://localhost:8082/widget-dist";
  })()
};
export const clientHost: string = getConfig().publicRuntimeConfig.host || "http://localhost:8060";
export const availableLanguages: string = getConfig().publicRuntimeConfig.availableLanguages || "es-CO";
