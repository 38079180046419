import Api from "@core/api.core";
import { AxiosRequestConfig } from "axios";
import { mergeRight } from "ramda";
import { host, endpoints } from "@configmegaMenu.config.ts/megaMenuApi.config";
import { ELandingType } from "@enumssearchHit.enum ";
import { TMegaMenuCard, TSlugBySectorIdSchema } from "#typesmegaMenu";

export class MegaMenuRepository extends Api {
  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: host }, baseOptions));
  }

  async getJobsByType(type: ELandingType): Promise<TMegaMenuCard[]> {
    return this.get<TMegaMenuCard[]>(endpoints.megaMenu.byType, { params: { type } })
      .then((response) => response.data)
      .catch(() => []);
  }
  async getSlugBySectorId(sectorId: number): Promise<TSlugBySectorIdSchema> {
    const TSlugBySectorIdBaseData = {
      id: "",
      slug: "",
      h1: ""
    } as TSlugBySectorIdSchema;

    return this.get<TSlugBySectorIdSchema>(endpoints.landing.slugBySectorId, { params: { sectorId } })
      .then((response) => response.data)
      .catch(() => TSlugBySectorIdBaseData);
  }
}

export default new MegaMenuRepository();
