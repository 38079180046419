import getConfig from "next/config";
import { mergeRight } from "ramda";
import { AxiosRequestConfig } from "axios";

import Api from "@core/api.core";
import { TEndpoint } from "#types/api.core";
import { ISession, TUser, TUserFlowPermission, TUserLogin, TUserPermission } from "#types/auth";
import { convertToUrlSearchParams } from "@utilsadapters/objectToUrlSearchParams.adapter";
import { ClientOauthID, ProfileRedirectUri } from "@constantsenvironment.constant";

const { oauth2Host, jobsApiHost } = getConfig().publicRuntimeConfig;

export class AuthRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    auth: {
      checkSession: "/v1/health/oauth-check",
      revokeChallenge: "/v2/oauth2/xtoken",
      token: "/v2/oauth2/secure",
      session: "/v2/oauth2/session",
      start: "/v2/auth-flow/start",
      validatePermission: "/v2/user/validatePermission",
      authorize: "/v2/oauth2/authorize",
      login: "/v2/oauth2/login"
    }
  };
  protected readonly grantType = "password";
  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: oauth2Host }, baseOptions));
  }

  checkSession(token: string): Promise<ISession> {
    const request = this.get<ISession>(this.endpoints.auth.checkSession, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return request.then((response) => response.data).catch(() => ({ isAuthenticated: false, user: null }));
  }

  async revokeChallenge(challenge: string): Promise<void> {
    const { revokeChallenge } = this.endpoints.auth;
    await this.delete<{ isDeleted: boolean }>(`${revokeChallenge}/${challenge}`);
  }

  async requestSessionByChallenge(challenge: string): Promise<ISession> {
    const payload: AxiosRequestConfig = {
      params: { challenge },
      baseURL: jobsApiHost,
      withCredentials: true
    };
    return this.get<{ user: TUser }>(this.endpoints.auth.token, payload).then(
      ({ data: { user } }) => ({ isAuthenticated: true, user }),
      () => ({ isAuthenticated: false, user: null })
    );
  }
  async refreshSession() {
    const payload: AxiosRequestConfig = {
      baseURL: jobsApiHost,
      withCredentials: true
    };

    return this.post<{ user: TUser }>(this.endpoints.auth.session, undefined, payload).then(
      ({ data: { user } }) => ({ isAuthenticated: true, user }),
      () => ({ isAuthenticated: false, user: null })
    );
  }

  async startFlow(): Promise<TUserFlowPermission> {
    const payload: TUserFlowPermission = {
      clientId: Buffer.from(ClientOauthID, "base64").toString("utf-8").split(":")[0] || "",
      scope: "ALL",
      state: "state",
      redirectUri: ProfileRedirectUri,
      responseType: "code"
    };
    return this.post<TUserFlowPermission>(this.endpoints.auth.start, payload)
      .then((response) => response.data)
      .catch(() => ({} as TUserFlowPermission));
  }

  async loginWithCredentials(username: string, password: string): Promise<boolean> {
    const user: TUserLogin = {
      username,
      password,
      // eslint-disable-next-line camelcase
      grant_type: this.grantType
    };
    const payload = convertToUrlSearchParams(user);
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Basic ${ClientOauthID}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      withCredentials: true
    };
    return this.post(this.endpoints.auth.session, payload, config)
      .then((response) => (response.status === 200 ? true : false))
      .catch(() => false);
  }

  async validatePermission(flowId?: string): Promise<boolean> {
    const payload: TUserPermission = {
      flowId: flowId || "",
      clientId: Buffer.from(ClientOauthID, "base64").toString("utf-8").split(":")[0] || "",
      scopes: [{ scope: "ALL", name: "Permiso total a su cuenta" }]
    };
    const headers = {
      Authorization: `Basic ${ClientOauthID}`
    };
    return this.post<TUserPermission>(this.endpoints.auth.validatePermission, payload, {
      headers,
      withCredentials: true
    })
      .then((response) => (response.status === 200 ? true : false))
      .catch(() => false);
  }

  async authorize(flowId?: string): Promise<{ [key: string]: string }> {
    if (!flowId) return { uri: "" };
    const config: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        Authorization: `Basic ${ClientOauthID}`
      }
    };
    return this.post<{ [key: string]: string }>(this.endpoints.auth.authorize, { flowId }, config)
      .then((response) => (response.status === 200 ? { uri: response.data.uri } : { uri: "" }))
      .catch(() => ({ uri: "" }));
  }
}

export default new AuthRepository();
