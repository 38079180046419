import { TEndpoint } from "#typesapi.core";
import getConfig from "next/config";

export const { seoApiHost: host } = getConfig().publicRuntimeConfig;
export const endpoints: TEndpoint = {
  megaMenu: {
    byType: "/v1/mega-menu/{type}",
    byOccupationCategory: "/v1/mega-menu/by-occupation/{parentSlug}"
  },
  landing: {
    slugBySectorId: "/v1/landing/slug/{sectorId}"
  }
};
