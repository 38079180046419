export enum ENotFoundType {
  "T404" = "404",
  "T500" = "500",
  "T410" = 410
}

export enum EVacantStatusType {
  T301 = 301,
  T202 = 202
}

export enum EVacancyRedirects {
  vacancyErrorRedirect = "vacancyErrorRedirect"
}
