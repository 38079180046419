/* eslint-disable camelcase */
import { ParsedUrlQuery } from "querystring";

export const EXPIRED_JOB_UTMS: Record<string, string> = {
  utm_source: "vacante-expirada",
  utm_medium: "referral",
  utm_campaign: "job-suggested"
};

export const getExpiredJobUtms = (utmTerm: string, originalJobSlug?: string): ParsedUrlQuery => {
  return {
    ...EXPIRED_JOB_UTMS,
    utm_term: utmTerm.includes("/") ? utmTerm.split("/").pop() || "" : utmTerm,
    utm_content: originalJobSlug || ""
  };
};
